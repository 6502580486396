import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import {Navigate, useLocation} from 'react-router-dom';
import {Configuration, TokenSession} from 'src/commons/models/commons';
import {User} from 'src/commons/models/user';
import api from 'src/commons/services/api';
import {
  AISDispatcherService,
  AISSettingsService,
  AISUserService,
} from 'src/commons/services/dataServices';
import StatusLoading from 'src/pages/status/status-loading';
// import AISRouterClass from '../ais-classes/router/ais-router';
import {CSTE_PERSMISSIONS, CSTE_ROUTES} from '../constants';
import {AISCacheService} from '../services/cacheService';

type authContextType = {
  user: User;
  configuration: Configuration;
  login: (
    login: string,
    password: string,
    onSuccess: Function,
    onFailed: Function,
    uuid?: string,
  ) => void;
  loginToDispatcher: (corporateKey: string, onSuccess: Function, onFailed: Function) => void;
  logout: () => void;
  check: (onFailed: Function, onSuccess?: Function) => void;
  checkApiConnexion: (onFailed: Function) => void;
  loading: boolean;
  updateSelectProfile: (profiles: Array<any>) => void;
  isAirIntUser: boolean;
  hasPermission: (feature: string) => boolean;
};

const authContextDefaultValues: authContextType = {
  user: {},
  configuration: {selectProfileOptions: []},
  login: () => {},
  loginToDispatcher: () => {},
  logout: () => {},
  check: () => {},
  checkApiConnexion: () => {},
  loading: true,
  updateSelectProfile: () => {},
  isAirIntUser: false,
  hasPermission: () => true,
};

const AuthContext = createContext<authContextType>(authContextDefaultValues);

export function useAuth() {
  return useContext(AuthContext);
}

type Props = {
  children: ReactNode;
};

export function AuthProvider({children}: Props) {
  const [user, setUser] = useState<User>(null);
  const [configuration, setConfiguration] = useState<Configuration>(null);
  const [loading, setLoading] = useState(true);
  const [isAirIntUser, setIsAirIntUser] = useState(false);

  useEffect(() => {
    function loadUserFromCookies() {
      const token = window.localStorage.getItem('token');

      if (token) {
        const objToken = JSON.parse(token);

        if (objToken.user) {
          setUser(objToken.user);
          setIsAirIntUser(
            objToken.user.name.toLowerCase().includes('airint') ||
              objToken.user.login.toLowerCase().includes('airint'),
          );
        }

        if (objToken.configuration) {
          setConfiguration(objToken.configuration);
        }
      }
      setLoading(false);
    }

    loadUserFromCookies();
  }, []);

  const login = async (
    login: string,
    password: string,
    onSuccess: Function,
    onFailed: Function,
    uuid?: string,
  ) => {
    try {
      const envUser = await AISDispatcherService.getUserEnv(
        AISCacheService.getApiValueFromDispatcher(true),
        AISCacheService.getCustomerIdFromDispatcher(),
      );

      const {data: response} = await AISUserService.login(login, password, envUser, uuid);

      if (response.status !== 200) {
        console.error(response.errors);
        onFailed(response.errors);
        return;
      }

      AISCacheService.setPreviousEnvironment();
      const token: TokenSession = response.results[0];

      api.defaults.headers.Authorization = `Bearer ${token.token}`;
      const user: User = token.user;

      user.isLogged = true;
      user.name = `${user.firstName} ${user.lastName}`;
      user.avatar = '/static/images/avatars/4.png';
      user.jobtitle = user.dummy_profileLabel;

      // Manage the Theme in customerOptions
      if (!AISCacheService.getCustomerIdFromDispatcher()) {
        AISCacheService.setCustomerIdToDispatcherResponse(user.CustomerId);
        try {
          const {data: responseTheme} = await AISSettingsService.getTheme(user.CustomerId);

          AISCacheService.setTheme(responseTheme.results[0]);
        } catch (error) {
          // we take the default theme
          AISCacheService.setTheme(null);
        }
      }

      AISCacheService.setToken(token);

      token.configuration.selectProfileOptions.forEach(p => {
        if (p.id === user.ProfileId) {
          user.jobtitle = p.name;
          user.dummy_profileLabel = p.name;
        }
      });

      AISCacheService.setToken(token);
      setConfiguration(token.configuration);
      setUser(user);
      setTimeout(() => {
        onSuccess();
      }, 500);
    } catch (error) {
      console.error('AIS-ERROR : Check dispatcher Error', error);
      onFailed(AISUserService.buildErrorAxios(error));
    }
  };

  const loginToDispatcher = async (
    corporateKey: string,
    onSuccess: Function,
    onFailed: Function,
  ) => {
    try {
      const {data: response} = await AISDispatcherService.loginToDispatcher(corporateKey);

      if (response.status !== 200) {
        onFailed(AISDispatcherService.buildErrorDispatcher(response.errors));
        return;
      }

      const dispatcherResponse = response.results[0];

      AISCacheService.setDispatcherResponse(dispatcherResponse, corporateKey);

      /// ////////////////////////////////////////////////
      // !!!!!! Very important, set the url of new api dynamicly.
      /// /////////////////////////////////////////////////
      api.defaults.baseURL = AISCacheService.getApiValueFromDispatcher();
      /// ////////////////////////////////////////////////////////////:///

      // Get get Theme in customerOptions
      if (AISCacheService.getCustomerId()) {
        const {data: responseTheme} = await AISSettingsService.getTheme(
          AISCacheService.getCustomerId(),
        );

        AISCacheService.setTheme(responseTheme.results[0]);
      } else {
        // we take the default theme
        AISCacheService.setTheme(null);
      }

      onSuccess();
    } catch (error) {
      console.error('AIS-ERROR : Login Error', error);
      onFailed(AISUserService.buildErrorAxios(error));
    }
  };

  // const logout = () => {
  const logout = async () => {
    const {data: resLogout} = await AISUserService.logout();

    if (resLogout === 'OK') {
      AISCacheService.removeItem('token');
      delete api.defaults.headers.Authorization;
      setUser(null);
    }
  };

  const updateSelectProfile = async (profiles: Array<any>) => {
    const token = window.localStorage.getItem('token');

    if (token) {
      const objToken = JSON.parse(token);

      if (objToken.configuration) {
        objToken.configuration.selectProfileOptions = profiles;
      }
      setConfiguration(objToken.configuration);
      window.localStorage.setItem('token', JSON.stringify(objToken));
    }
  };

  const check = async (onFailed: Function, onSuccess?: Function) => {
    try {
      const {data: resCheck} = await AISUserService.check();

      if (resCheck.status !== 200) {
        onFailed(resCheck.errors);
        return;
      }

      if (resCheck.results[0].checkStatus === 'KO') {
        onFailed(['The server session has expired, you need to reconnect']);
        return;
      }

      if (onSuccess) {
        onSuccess();
      }
      // console.log( resCheck)
      // if ( !resCheck.success) onFailed()
    } catch (error) {
      onFailed();
    }
  };

  const checkApiConnexion = async (onFailed: Function) => {
    try {
      const {data: resCheck} = await AISUserService.checkApiConnection();

      if (resCheck.status !== 200) {
        onFailed(resCheck.errors);
      }
      // console.log( resCheck)
      // if ( !resCheck.success) onFailed()
    } catch (error) {
      onFailed();
    }
  };

  const hasPermission = (feature: string): boolean => {
    if (AISCacheService.getModeSuperUser()) {
      return true;
    }
    let checkFeature = feature;
    let checkPermission = false;
    if (feature.startsWith('/')) {
      // eslint-disable-next-line no-restricted-syntax
      for (const property in CSTE_PERSMISSIONS) {
        if (property.startsWith('/')) {
          if (feature.startsWith(property)) {
            checkPermission = true;
            checkFeature = property;
          }
        }
      }
    } else {
      checkPermission = CSTE_PERSMISSIONS[checkFeature];
    }

    if (checkPermission) {
      const token = AISCacheService.getToken();

      if (!token) {
        return false;
      }
      return token.user.permissions.features.some(
        f =>
          f.name === CSTE_PERSMISSIONS[checkFeature].name &&
          f.type === CSTE_PERSMISSIONS[checkFeature].type &&
          f.applicationSection === CSTE_PERSMISSIONS[checkFeature].applicationSection,
      );
    }
    return true;
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    user,
    configuration,
    login,
    loginToDispatcher,
    logout,
    check,
    checkApiConnexion,
    loading,
    updateSelectProfile,
    isAirIntUser,
    hasPermission,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function ProtectRoute({children}) {
  const {user, loading, hasPermission} = useAuth();
  const routerLocation = useLocation();
  // const router = new AISRouterClass();

  if (loading) {
    return <StatusLoading />;
  }

  if (!routerLocation || !routerLocation.pathname) {
    console.error('AUTH context => ProtectRoute => routerLocation is undefined');
    console.error(`AUTH context window.location.href = ${window.location.href}`);
    return children;
  }

  const pathname = routerLocation.pathname;

  if (pathname !== CSTE_ROUTES.COMMONS.SSO_AUTHENTICATED) {
    if (!user || (user && !user.isLogged)) {
      if (
        pathname !== CSTE_ROUTES.COMMONS.INDEX &&
        pathname !== CSTE_ROUTES.COMMONS.FORBIDDEN &&
        pathname !== CSTE_ROUTES.COMMONS.SSO_AUTHENTICATED
      ) {
        AISCacheService.removeTokenIsExpired();
        return <Navigate to={CSTE_ROUTES.COMMONS.LOGIN} replace />;
      }
    } else {
      // here add permissions for accessing differents routes

      const module = pathname.split('/')[1];

      AISCacheService.setCurrentModule(module);
      const hasPermissionModule = hasPermission(module);
      const hasPermissionFeature = hasPermission(pathname);

      if (!hasPermissionModule || !hasPermissionFeature) {
        if (AISCacheService.tokenIsExpired()) {
          return children;
        }
        return <Navigate to={CSTE_ROUTES.COMMONS.FORBIDDEN} replace />;
      }
    }
  }

  return children;
}
